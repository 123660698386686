import React, { useState } from 'react';
import { Button, TextField, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import routes from '../routes';
import { useAuth } from '../hooks/useAuth';
import { debounce } from 'lodash';
import axios from 'axios';

const LoginForm = ({ onBack }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const [error, setError] = useState('');
  const { handleLogin, isLoading, error: authError, isCookiePresent, checkAuthStatus } = useAuth();
  const navigate = useNavigate();

  // Debounced login with navigation
  const debouncedLogin = debounce(async () => {
    try {
      const loginResponse = await handleLogin(email, password);
      if (loginResponse?.customer_id) {
        console.log('Login successful, fetching chat history');

        const chatHistoryResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/chats/${loginResponse.customer_id}`
        );

        if (chatHistoryResponse.status === 200) {
          console.log('Chat history fetched successfully, storing in localStorage');
          localStorage.setItem('chatHistory', JSON.stringify(chatHistoryResponse.data));
        }
        
        await checkAuthStatus();
        navigate(routes.chat);
        console.log('tracking cookie value in login.js debounced function: ', isCookiePresent);
        console.log('Navigating to chat');
      }
      else {
        console.log('Login failed, customer_id not found');
      }
    } catch (error) {
      console.log('Login failed:', error);
    }
  }, 500);

  // Verify whether the email exists in the database.
  const handleEmailSubmit = async () => {
    if (!email) {
      setError('Please enter a valid email address.');
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/auth/check-email`,
        { params: { email } }
      );
      if (response.data.exists) {
        setEmailSubmitted(true);
        setError('');
      } else {
        // Show that email doesn't exist and let the user try again.
        setError("No account found with that email. Please try again.");
      }
    } catch (err) {
      console.error("Error checking email existence:", err);
      setError("An error occurred while verifying the email. Please try again.");
    }
  };

  const handleLoginClick = () => {
    if (!isLoading && email && password) {
      debouncedLogin();
    }
  };

  return (
    <Box mt={4} bgcolor="#f1f0e8" p={4} borderRadius={3}>
      {!emailSubmitted ? (
        <>
          <TextField
            label="Email address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button 
            variant="contained" 
            fullWidth 
            onClick={handleEmailSubmit}
            sx={{ 
              marginTop: '20px', 
              backgroundColor: '#c65e41',
              '&:hover': { backgroundColor: '#b54e33' },
            }}
          >
            Continue
          </Button>
        </>
      ) : (
        <>
          {/* Display the email so the user can verify which account is being used */}
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Logging in as: {email}
          </Typography>
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            fullWidth
            disabled={isLoading}
            onClick={handleLoginClick}
            sx={{ 
              marginTop: '20px', 
              backgroundColor: '#c65e41',
              '&:hover': { backgroundColor: '#b54e33' },
            }}
          >
            {isLoading ? 'Logging in...' : 'Login'}
          </Button>
        </>
      )}
      {(error || authError) && (
        <Typography color="error" style={{ marginTop: '10px' }}>
          {error || authError}
        </Typography>
      )}
      <Button
        variant="text"
        onClick={onBack}
        sx={{ 
          marginTop: '10px',
          color: '#3C392B',
          '&:hover': { backgroundColor: 'rgba(198, 94, 65, 0.04)' },
        }}
      >
        Back
      </Button>
    </Box>
  );
};

export default LoginForm;
