import React, { useState } from "react";
import { Button, TextField, Typography, Box, CircularProgress } from '@mui/material';
import axios from "axios";
import { useAuth } from '../hooks/useAuth';
import OTPVerification from "./OTPVerification";
import routes from '../routes';

/**
 * SignupForm Component
 * @param {Object} props
 * @param {Function} props.onBack - Handler to return to main auth options
 * @param {Function} props.onSignupSuccess - Handler called after successful OTP verification (e.g. redirect to /chat)
 */
const SignupForm = ({ onBack, onSignupSuccess }) => {
  const { handleLogin } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  /**
   * Handles the signup form submission.
   * If the OTP is sent successfully, then we show OTPVerification.
   * If sending the email fails (for instance due to a simple typo in the email),
   * then we display an error message below the email input.
   */
  const handleSignup = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/signup`,
        { name, email, password },
        { withCredentials: true }
      );
      
      // OTP successfully sent by the backend.
      if (response.data.message === "OTP sent to email") {
        setOtpSent(true);
      }
    } catch (error) {
      const errorDetail = error.response?.data?.detail;
      // If the backend indicates OTP email sending failed, we show a clear error.
      if (errorDetail === "Failed to send verification email") {
        setMessage("Please enter a valid email address");
      } else {
        setMessage(errorDetail || "Signup failed");
      }
    } finally {
      setLoading(false);
    }
  };

  if (otpSent) {
    return (
      <OTPVerification 
        email={email}
        onVerified={onSignupSuccess}
        onBack={onBack} 
      />
    );
  }

  return (
    <Box mt={4} bgcolor="#f1f0e8" p={4} borderRadius={3}>
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        margin="normal"
        disabled={loading}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="Email address"
        variant="outlined"
        fullWidth
        margin="normal"
        disabled={loading}
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setMessage(""); // clear any prior error when user modifies the email
        }}
        error={Boolean(message)}
        helperText={message}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
        disabled={loading}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={loading}
        style={{ marginTop: '20px', backgroundColor: '#2EB872' }}
        onClick={handleSignup}
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign Up'}
      </Button>
      <Button
        variant="text"
        onClick={onBack}
        style={{ marginTop: '10px' }}
        disabled={loading}
      >
        Back
      </Button>
    </Box>
  );
};

export default SignupForm;
