import React, { useState, useRef } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import routes from '../routes';
import { useAuth } from '../hooks/useAuth';

const handleStartConversation = async (customerId) => {
  try {
    console.log('[handleStartConversation] Starting conversation for customer:', customerId);
    await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/start_conversation`,
      { customer_id: customerId },
      { withCredentials: true }
    );
    console.log('[handleStartConversation] Conversation started successfully');
  } catch (error) {
    console.error('[handleStartConversation] Error starting conversation:', error);
  }
};

// Create a styled input component that is responsive for mobile screens
const OtpInput = styled('input')(({ theme }) => ({
  width: '50px',
  height: '50px',
  fontSize: '1.5rem',
  textAlign: 'center',
  border: '1px solid #c65e41',
  borderRadius: '8px',
  marginRight: '10px',
  outline: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '32px',
    height: '40px',
    fontSize: '1.2rem',
    marginRight: '9px',
  },
  '&:last-child': {
    marginRight: 0,
  },
}));

/**
 * OTPVerification Component
 * @param {Object} props
 * @param {string} props.email - The email address to verify (passed from Signup)
 * @param {Function} props.onVerified - Callback after successful OTP verification
 * @param {Function} props.onBack - Callback if the user wants to cancel verification
 */
const OTPVerification = ({ email: propEmail, onVerified, onBack }) => {
  console.log('[OTPVerification] Component mounted');
  // State for each OTP digit (6 digits total)
  const [otpDigits, setOtpDigits] = useState(new Array(6).fill(''));
  const inputsRef = useRef([]);
  const [message, setMessage] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [resending, setResending] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  // Use the email passed in as a prop; fallback to location.state if not provided.
  const email = propEmail || location.state?.email;
  const { checkAuthStatus } = useAuth();

  console.log('[OTPVerification] Email used:', email);

  if (!email) {
    console.log('[OTPVerification] No email found');
    return (
      <Box mt={4} bgcolor="#f1f0e8" p={4} borderRadius={3}>
        <Typography>Invalid verification attempt. Please try signing up again.</Typography>
      </Box>
    );
  }

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d?$/.test(value)) { // Allow only a digit or empty input.
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index] = value;
      setOtpDigits(newOtpDigits);
      if (value && index < 5) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otpDigits[index] === '' && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleVerify = async () => {
    const otp = otpDigits.join('');
    console.log('[handleVerify] Attempting to verify OTP for email:', email, 'otp:', otp);
    setVerifying(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/verify-email`,
        { email, otp },
        { withCredentials: true }
      );
      
      console.log('[handleVerify] Verification response:', response);
      
      if (response.status === 200) {
        const customerId = response.data.customer_id;
        console.log('[handleVerify] Verification successful, customer ID:', customerId);
        await handleStartConversation(customerId);
        await checkAuthStatus();
        if (onVerified) {
          onVerified();
        } else {
          navigate(routes.chat);
        }
      }
    } catch (error) {
      console.error('[handleVerify] Verification error:', error);
      const errorMessage = error.response?.data?.detail || 'Verification failed';
      setMessage(errorMessage);
    } finally {
      setVerifying(false);
    }
  };

  const handleResend = async () => {
    console.log('[handleResend] Attempting to resend OTP for email:', email);
    setResending(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/resend-otp`, 
        { email }
      );
      console.log('[handleResend] OTP resent successfully');
      setMessage('New OTP sent to your email');
    } catch (error) {
      console.error('[handleResend] Error resending OTP:', error);
      const errorMessage = error.response?.data?.detail || 'Failed to resend OTP';
      setMessage(errorMessage);
    } finally {
      setResending(false);
    }
  };

  const disableAll = verifying || resending;

  return (
    <Box mt={4} bgcolor="#f1f0e8" p={4} borderRadius={3} textAlign="center">
      <Typography variant="h6" gutterBottom>
        Verify Email Address
      </Typography>
      <Typography variant="body2" gutterBottom>
        Please enter the verification code sent to {email}
      </Typography>
      
      {/* OTP Input Bubbles */}
      <Box display="flex" justifyContent="center" mt={2}>
        {otpDigits.map((digit, index) => (
          <OtpInput
            key={index}
            type="text"
            inputMode="numeric"
            maxLength={1}
            disabled={disableAll}
            value={digit}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => inputsRef.current[index] = el}
          />
        ))}
      </Box>
      
      {message && (
        <Typography 
          color={message.includes('sent') ? 'success.main' : 'error.main'} 
          style={{ marginTop: '10px' }}
        >
          {message}
        </Typography>
      )}
      
      <Button 
        variant="contained"
        fullWidth 
        disabled={disableAll}
        onClick={handleVerify}
        style={{ marginTop: '20px', backgroundColor: '#c65e41', color: '#fff' }}
      >
        {verifying ? <CircularProgress size={24} color="inherit" /> : 'Verify'}
      </Button>
      <Button
        variant="text"
        onClick={handleResend}
        disabled={disableAll}
        style={{ marginTop: '10px', color: '#c65e41' }}
      >
        {resending ? <CircularProgress size={20} color="inherit" /> : 'Resend OTP'}
      </Button>
      {onBack && (
        <Button 
          variant="text" 
          onClick={onBack} 
          style={{ marginTop: '10px', color: '#c65e41' }}
          disabled={disableAll}
        >
          Back
        </Button>
      )}
    </Box>
  );
};

export default OTPVerification;