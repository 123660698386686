import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HexagonStarsIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      {/* Hexagon shape */}
      <polygon points="12,2 19,7 19,17 12,22 5,17 5,7" fill="currentColor" />
      {/* Left Star */}
      <path 
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        fill="#fff"
        transform="translate(2,8) scale(0.4)"
      />
      {/* Right Star */}
      <path 
        d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        fill="#fff"
        transform="translate(13,8) scale(0.1)"
      />
    </SvgIcon>
  );
};

export default HexagonStarsIcon; 