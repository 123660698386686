import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, CardActions, Switch, FormControlLabel, Button } from '@mui/material';
import axios from 'axios';

const Pricing = ({ onSubscribe }) => {
  const [isUSD, setIsUSD] = useState(false);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [pricingPlans, setPricingPlans] = useState([]);
  const [usdToInrRate, setUsdToInrRate] = useState(0);

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/subscription-plans`);
        const { plans, currency_settings } = response.data;
        
        setUsdToInrRate(currency_settings.usd_to_inr_rate);
        
        // Transform plans data into component format

        // yearly and monthly both included code block
        // const formattedPlans = Object.entries(plans).map(([type, plan]) => ({
        //   title: `Pro ${type.charAt(0).toUpperCase() + type.slice(1)}`,
        //   price: `$${plan.amount}/${type === 'monthly' ? 'month' : 'year'}`,
        //   plan_type: type,
        //   features: plan.features
        // }));

        // Remove later: only monthly plans included code block
        const formattedPlans = Object.entries(plans)
          .filter(([type]) => type === 'monthly') // Only show monthly plans for now
          .map(([type, plan]) => ({
            title: `Pro ${type.charAt(0).toUpperCase() + type.slice(1)}`,
            price: `$${plan.amount}/${type === 'monthly' ? 'month' : 'year'}`,
            plan_type: type,
            features: plan.features
          }));
        
        setPricingPlans(formattedPlans);
      } catch (error) {
        console.error('Error fetching pricing plans:', error);
      }
    };

    fetchPricingPlans();
  }, []);

  const convertToINR = (usdPrice) => {
    return `₹${(parseFloat(usdPrice.replace('$', '')) * usdToInrRate).toFixed(2)}`;
  };

  return (
    <Box py={8} bgcolor="#f1f0e8">
      <Container maxWidth="lg">
        <Typography variant="h4" align="center" gutterBottom>
          Upgrade Your Plan
        </Typography>
        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
          <FormControlLabel
            control={
              <Switch
                checked={isUSD}
                onChange={() => setIsUSD(!isUSD)}
                color="default"
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#c65e41',
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#c65e41',
                  },
                }}
              />
            }
            label={isUSD ? 'USD' : 'INR'}
            labelPlacement="start"
            sx={{
              color: '#3C392B',
              fontSize: '1.2rem',
            }}
          />
        </Box>
        <Grid container spacing={4} justifyContent="center">
          {pricingPlans.map((plan, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card>
                <CardContent>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {plan.title}
                  </Typography>
                  <Typography variant="h4" color="primary" gutterBottom>
                    {plan.price === 'Free' ? 'Free' : (isUSD ? plan.price : convertToINR(plan.price))}
                  </Typography>
                  {plan.features.map((feature, idx) => (
                    <Typography key={idx} variant="body1" paragraph>
                       {feature}
                    </Typography>
                  ))}
                </CardContent>
                <CardActions>
                  <Button 
                    fullWidth 
                    variant="contained" 
                    style={{ backgroundColor: '#c65e41' }}
                    onClick={() => {
                      setIsSubscribing(true);
                      onSubscribe({ type: plan.plan_type });
                    }}
                  >
                    {isSubscribing ? 'Subscribing...' : 'Subscribe Now'}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Pricing; 