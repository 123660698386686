const routes = {
  home: '/',
  chat: '/chat',
  subscription: '/subscription',
  settings: '/settings',
  googleCallback: '/auth/google/callback',
  terms: '/terms',
  privacyPolicy: '/privacy-policy',
  about: '/about',
  pricing: '/pricing',
  verifyotp: '/verify-email',
  paymentFailure: '/payment/failure'
};

export default routes;
