import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import HexagonStarsIcon from './HexagonStarsIcon';

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '1rem', // Adjust to match the logout button's font size
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  borderRadius: 4,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  transition: 'background-color 0.3s, box-shadow 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: theme.shadows[4],
  },
}));

const ViewPlansButton = (props) => {
  return (
    <StyledButton
      startIcon={<HexagonStarsIcon />}
      {...props}
    >
      {props.children || 'View Plans'}
    </StyledButton>
  );
};

export default ViewPlansButton; 