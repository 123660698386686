import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, Typography, Box, IconButton, Container } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import routes from '../routes';
import './Chat.css';
import TypewriterText from './TypewriterText';
import { isEqual } from 'lodash';
import Pricing from './Pricing';
import Dialog from '@mui/material/Dialog';
import ViewPlansButton from './ViewPlansButton';

const Chat = () => {
  // Chat state - declare messages first
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  
  // Synchronous ref to block multiple sends immediately.
  const sendInProgressRef = useRef(false);
  
  // Conditional top padding based on messages (now messages is declared above)
  const HEADER_PADDING = messages.length === 0
    ? { xs: '250px', sm: '100px' }
    : { xs: '150px', sm: '60px' };

  // Core auth state from useAuth hook
  const { customerId, isCookiePresent, handleLogout } = useAuth();
  
  // Subscription and trial state
  const [isTrialExceeded, setIsTrialExceeded] = useState(false); // Whether user has exceeded free trial messages
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false); // Whether user has active paid subscription
  const [subscriptionType, setSubscriptionType] = useState(null); // Type of subscription (basic/premium etc)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false); // Controls visibility of upgrade modal
  
  // NEW: state to indicate the user dismissed the pricing modal.
  const [trialEndedDismissed, setTrialEndedDismissed] = useState(false);
  
  // Navigation and refs
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const chatMessagesRef = useRef(null);
  const [stickyDate, setStickyDate] = useState(null);

  // Free trial message limit from env vars
  const FREE_TRIAL_LIMIT = parseInt(process.env.REACT_APP_FREE_TRIAL_MESSAGES_LIMIT, 50) || 50;

  // Disable window scroll when on the welcome screen
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [messages.length]);

  // Initial auth check and chat history load
  useEffect(() => {
    if (isCookiePresent) {
      navigate(routes.chat);
      loadChatHistory();
    }
    else {
      navigate(routes.home);
      return;
    }
  }, [isCookiePresent, navigate]);

  // Auto-scroll on new messages
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Handle sticky date header on scroll
  useEffect(() => {
    const chatContainer = chatMessagesRef.current;
    if (chatContainer) {
      const handleScroll = () => {
        const dateHeaders = chatContainer.querySelectorAll('.date-header');
        let visibleHeader = null;

        for (const header of dateHeaders) {
          const rect = header.getBoundingClientRect();
          if (rect.top <= 60 && rect.bottom > 60) {
            visibleHeader = header;
            break;
          }
        }

        setStickyDate(visibleHeader ? visibleHeader.textContent : null);
      };

      chatContainer.addEventListener('scroll', handleScroll);
      return () => chatContainer.removeEventListener('scroll', handleScroll);
    }
  }, [messages]);

  // Listen for chat history updates from other components
  useEffect(() => {
    const handleChatHistoryUpdate = () => {
      loadChatHistory();
    };

    window.addEventListener('chatHistoryUpdated', handleChatHistoryUpdate);

    return () => {
      window.removeEventListener('chatHistoryUpdated', handleChatHistoryUpdate);
    };
  }, []);

  // Check subscription status on mount and when customerId changes
  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/subscription-status/${customerId}`,
          { withCredentials: true }
        );
        
        // Destructure response with default values
        const { 
          has_active_subscription = false,
          messages_remaining = FREE_TRIAL_LIMIT,
          status = 'trial_active'
        } = response.data;

        // Calculate trial states
        const isTrial = status.includes('trial');
        const validTrial = status === 'trial_active' && messages_remaining > 0;
        const trialExceeded = status === 'trial_ended' || (status === 'trial_active' && messages_remaining <= 0);
        const freeMessagesRemaining = response.data.messages_remaining;

        setIsTrialExceeded(!isTrial ? false : trialExceeded);
        setHasActiveSubscription(has_active_subscription);
        setSubscriptionType(response.data.plan_type);

      } catch (error) {
        // Fallback to trial state if API fails
        setIsTrialExceeded(false);
        setHasActiveSubscription(false);
      }
    };
    
    if (customerId) {
      checkSubscriptionStatus();
    }
  }, [customerId]);

  // Sync messages with localStorage on auth changes
  useEffect(() => {
    const handleAuthChange = () => {
      const history = JSON.parse(localStorage.getItem('chatHistory') || '[]');
      if (!isEqual(messages, history)) {
        setMessages(history);
      }
    };

    window.addEventListener('authStateChanged', handleAuthChange);
    return () => window.removeEventListener('authStateChanged', handleAuthChange);
  }, [messages]);

  // Automatically open the pricing modal when the trial is exceeded and no active subscription exists.
  useEffect(() => {
    if (isTrialExceeded && !hasActiveSubscription && !showSubscriptionModal && !trialEndedDismissed) {
      setShowSubscriptionModal(true);
    }
  }, [isTrialExceeded, hasActiveSubscription, showSubscriptionModal, trialEndedDismissed]);

  // When subscription becomes active, reset any trial dismissed state
  useEffect(() => {
    if (hasActiveSubscription) {
      setTrialEndedDismissed(false);
    }
  }, [hasActiveSubscription]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Load chat history from localStorage
  const loadChatHistory = () => {
    const storedChatHistory = localStorage.getItem('chatHistory');
    if (storedChatHistory) {
      const normalizedMessages = JSON.parse(storedChatHistory).map(msg => ({
        role: msg.role,
        content: msg.message || msg.content,
        timestamp: new Date(msg.timestamp),
        date: new Date(msg.timestamp).toISOString().split('T')[0]
      }));
      setMessages(normalizedMessages);
    }
  };

  // Handle sending new messages
  const handleSendMessage = async () => {
    // Immediately block further sends.
    if (sendInProgressRef.current) return;
    if (!customerId || !userInput.trim()) return;
    
    sendInProgressRef.current = true;  // Block further sends immediately.
    
    try {
      // Check subscription status before sending message
      const subscriptionResponse = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/subscription-status/${customerId}`,
        { withCredentials: true }
      );
      
      const { has_active_subscription, messages_remaining, status } = subscriptionResponse.data;
      setHasActiveSubscription(has_active_subscription);
      
      // Use messages_remaining instead of free_messages_remaining
      setIsTrialExceeded(status === 'trial_ended' || (status === 'trial_active' && messages_remaining <= 0));

      if (!has_active_subscription && messages_remaining <= 0) {
        setIsTrialExceeded(true);
        return;
      }

      // Add user message to chat
      const newUserMessage = {
        role: 'user',
        content: userInput,
        timestamp: new Date(),
      };
      
      setMessages(prev => {
        const updatedMessages = [...prev, newUserMessage];
        localStorage.setItem('chatHistory', JSON.stringify(updatedMessages));
        return updatedMessages;
      });
      
      setUserInput('');
      setIsTyping(true);

      // Send message to backend
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/continue_conversation`,
        { customer_id: customerId, query: userInput },
        { withCredentials: true }
      );

      // Get updated chat history from backend
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/chats/${customerId}`
      );
      
      // Update local storage if backend has more messages
      if (data.length > messages.length) {
        localStorage.setItem('chatHistory', JSON.stringify(data));
        window.dispatchEvent(new Event('chatHistoryUpdated'));
      }

    } catch (error) {
      // Handle error silently
    } finally {
      setIsTyping(false);
      sendInProgressRef.current = false; // Unblock sending.
    }
  };

  // Handle subscription purchase
  const handleSubscription = async (plan) => {
    try {
      // Add null check and default value
      const planType = plan?.type?.toLowerCase() || 'monthly';
      
      const requestPayload = {
        customer_id: Number(customerId),
        plan_type: planType
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/payment/subscribe`,
        requestPayload,
        { withCredentials: true }
      );

      // Create hidden form for PayU redirect
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = response.data.action_url;

      // Add payment parameters to form
      Object.entries(response.data.params).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();

    } catch (error) {
      alert(`Subscription failed: ${error.response?.data?.detail || error.message}`);
    }
  };

  // Updated key press handler:
  // If the user presses Enter (without Shift) and we're not waiting for a response, send the message.
  // Otherwise, let the key event propagate so the user can continue typing.
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (!sendInProgressRef.current) {
        handleSendMessage();
      }
    }
  };

  return (
    <Container 
      maxWidth="xl"
      sx={{ 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        position: 'relative',
      }}
    >
      {/* Logout button */}
      <Box 
        sx={{ 
          position: 'fixed',
          top: 16,
          right: { xs: 24, sm: 32, md: 48 },
          zIndex: 1000,
        }}
      >
        <Button 
          onClick={handleLogout} 
          variant="contained"
          sx={{
            backgroundColor: '#c65e41',
            fontSize: { xs: '1.2rem', sm: '1.4rem' },
            padding: { xs: '10px 24px', sm: '12px 32px' },
            '&:hover': { backgroundColor: '#b54e31' }
          }}
        >
          Logout
        </Button>
      </Box>

      {/* Subscription status/upgrade button */}
      <Box sx={{ 
        position: 'fixed',
        top: 16,
        left: 24,
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        gap: 1.5
      }}>
        {hasActiveSubscription ? (
          <IconButton 
            sx={{
              backgroundColor: '#4CAF50',
              cursor: 'default'
            }}
          >
            <Typography variant="body2" sx={{ color: 'white', fontWeight: 'bold' }}>
              Pro
            </Typography>
          </IconButton>
        ) : (
          // Only show the upgrade button if the free messages have been exhausted.
          isTrialExceeded && <ViewPlansButton onClick={() => setShowSubscriptionModal(true)} />
        )}
        {hasActiveSubscription && (
          <Typography variant="caption" sx={{ color: '#4CAF50', fontWeight: 'bold' }}>
            Subscription Active
          </Typography>
        )}
      </Box>

      {messages.length === 0 ? (
        // New User Layout: Disable window scrolling on welcome screen.
        <Box 
          sx={{
            position: 'relative',
            width: '100%',
            maxWidth: '1000px',
            textAlign: 'center',
            mb: 2,
            mt: HEADER_PADDING, // This ensures the welcome message starts below header icons.
          }}
        >
          <TypewriterText 
            text="👋 Welcome to Ayna! I'm your AI therapist"
            sx={{
              textAlign: 'center',
              mb: 4,
              color: '#3C392B',
              fontWeight: 500,
            }}
          />

          {(isTrialExceeded && !hasActiveSubscription && trialEndedDismissed) ? (
            <Box mt={2}>
              <Button 
                variant="contained" 
                onClick={() => {
                  setTrialEndedDismissed(false);
                  setShowSubscriptionModal(true);
                }}
                sx={{ backgroundColor: '#c65e41' }}
              >
                Your trial has ended, Subscribe now
              </Button>
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSendMessage();
              }}
              sx={{
                display: 'flex',
                gap: 1,
                p: 2,
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
            >
              <TextField
                fullWidth
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={handleKeyPress}
                placeholder="Type your message here..."
                variant="outlined"
                multiline
                maxRows={15}
                sx={{
                  maxHeight: 200,
                  overflow: 'auto',
                  '& .MuiOutlinedInput-root': { 
                    borderRadius: 2, 
                    overflow: 'auto',
                    '& fieldset': {
                      borderColor: 'rgba(198, 94, 65, 0.3)',
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgba(198, 94, 65, 0.5)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#c65e41',
                      borderWidth: '2px',
                    },
                  },
                }}
              />
              <IconButton 
                type="submit"
                disabled={sendInProgressRef.current}
                sx={{ 
                  color: '#c65e41',
                  '&:hover': {
                    backgroundColor: 'rgba(198, 94, 65, 0.04)'
                  }
                }}
              >
                <SendIcon />
              </IconButton>
            </Box>
          )}

          <Box
            sx={{
              mt: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                backgroundColor: '#f8f7f2',
                p: 2,
                borderRadius: 2,
                border: '1px dashed #3C392B',
                maxWidth: '80%',
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#f1f0e8' },
              }}
            >
              You can share anything that's on your mind. I'm here to listen and support you.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                backgroundColor: '#f8f7f2',
                p: 2,
                borderRadius: 2,
                border: '1px dashed #3C392B',
                maxWidth: '80%',
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#f1f0e8' },
              }}
            >
              Ayna learns from your conversations about your life. Feel free to share anything that's on your mind like you would with a friend.
            </Typography>
          </Box>
        </Box>
      ) : (
        // Conversation layout: chat messages container with top padding to clear the header icons.
        <>
          <Box
            ref={chatMessagesRef}
            className="chat-messages"
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 2,
              position: 'relative',
              width: '100%',
              maxWidth: { xs: '800px', md: '1400px' },
              px: { xs: 2, sm: 3, md: 4 },
              overflowY: 'auto',
              mt: HEADER_PADDING,
              paddingTop: '60px',
            }}
          >
            {messages.map((msg, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent={msg.role === 'user' ? 'flex-end' : 'flex-start'}
                mb={1}
                sx={{
                  width: '100%',
                }}
              >
                <Box
                  bgcolor={msg.role === 'user' ? '#c65e41' : '#f1f0e8'}
                  p={2}
                  borderRadius={2}
                  maxWidth={{ xs: "70%", md: "50%" }}
                  sx={{ 
                    wordBreak: 'break-word',
                    ml: msg.role === 'user' ? 'auto' : 0,
                    mr: msg.role === 'user' ? 0 : 'auto'
                  }}
                >
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: msg.role === 'user' ? '#ffffff' : '#3C392B',
                      fontFamily: msg.role === 'user' ? '"Fira Code", monospace' : 'Helvetica, Arial, sans-serif',
                    }}
                  >
                    {msg.content}
                  </Typography>
                </Box>
              </Box>
            ))}
            
            {isTyping && (
              <Box display="flex" justifyContent="flex-start" mb={1}>
                <Box
                  bgcolor="#f1f0e8"
                  p={2}
                  borderRadius={2}
                  className="typing-indicator"
                >
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </Box>
              </Box>
            )}
            <div ref={messagesEndRef} />
          </Box>
          {(isTrialExceeded && !hasActiveSubscription && trialEndedDismissed) ? (
            <Box sx={{ mt: 2, width: '100%', maxWidth: '1000px', mx: 'auto' }}>
              <Button 
                variant="contained" 
                fullWidth
                onClick={() => {
                  setTrialEndedDismissed(false);
                  setShowSubscriptionModal(true);
                }}
                sx={{ bgcolor: '#c65e41' }}
              >
                Your trial has ended, Subscribe now
              </Button>
            </Box>
          ) : (
            <Box 
              sx={{
                mt: 2,
                width: '100%',
                maxWidth: { xs: '800px', md: '1400px' },
                mx: 'auto'
              }}
            >
              <Box
                component="form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSendMessage();
                }}
                sx={{
                  display: 'flex',
                  gap: 1,
                  p: 2,
                  backgroundColor: 'white',
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
              >
                <TextField
                  fullWidth
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  onKeyDown={handleKeyPress}
                  placeholder="Type your message here..."
                  variant="outlined"
                  multiline
                  maxRows={15}
                  sx={{
                    maxHeight: 200,
                    overflow: 'auto',
                    '& .MuiOutlinedInput-root': { 
                      borderRadius: 2, 
                      overflow: 'auto',
                      '& fieldset': {
                        borderColor: 'rgba(198, 94, 65, 0.3)',
                      },
                      '&:hover fieldset': {
                        borderColor: 'rgba(198, 94, 65, 0.5)',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#c65e41',
                        borderWidth: '2px',
                      },
                    },
                  }}
                />
                <IconButton 
                  type="submit" 
                  disabled={sendInProgressRef.current}
                  sx={{ 
                    color: '#c65e41',
                    '&:hover': {
                      backgroundColor: 'rgba(198, 94, 65, 0.04)'
                    }
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Box>
            </Box>
          )}
        </>
      )}

      {/* Subscription modal - should show when button clicked */}
      <Dialog 
        open={showSubscriptionModal} 
        onClose={() => {
          setShowSubscriptionModal(false);
          // When the dialog is dismissed (e.g. by clicking the backdrop) and the trial is exceeded,
          // we mark that the user dismissed the pop-up so the chat input is replaced with the banner button.
          if (isTrialExceeded && !hasActiveSubscription) {
            setTrialEndedDismissed(true);
          }
        }}
        fullWidth 
        maxWidth="md"
      >
        {hasActiveSubscription ? (
          <Box sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="h5" gutterBottom>
              Your Current Plan
            </Typography>
            <Typography variant="h6" sx={{ color: '#4CAF50', mb: 2 }}>
              {subscriptionType ? `Pro ${subscriptionType}` : 'Pro Plan'}
            </Typography>
            <Button 
              variant="contained" 
              onClick={() => setShowSubscriptionModal(false)}
              sx={{ mt: 2, bgcolor: '#c65e41' }}
            >
              Close
            </Button>
          </Box>
        ) : (
          <>
            {isTrialExceeded && (
              <Typography
                variant="h6"
                align="center"
                sx={{ p: 2, color: '#c65e41' }}
              >
                Your trial has ended.
              </Typography>
            )}
            <Pricing onSubscribe={handleSubscription} />
          </>
        )}
      </Dialog>
    </Container>
  );
};

export default Chat;
