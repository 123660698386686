import React, { useEffect } from 'react';
import { Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function PaymentFailure() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/chat');
    }, 3000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 4, textAlign: 'center', color: 'red' }}>
        Payment Failed
      </Typography>
      <Typography sx={{ mt: 2, textAlign: 'center' }}>
        Something went wrong with the payment. Please try again or contact support.
      </Typography>
      <Typography sx={{ mt: 2, textAlign: 'center' }}>
        Redirecting back to chat...
      </Typography>
    </Container>
  );
}

export default PaymentFailure; 